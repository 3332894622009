import { createSelector } from 'reselect';

export const dashboardInfoSelector = createSelector(
    (state) => state.user,
    (app) => app.dashboardHome,
);


export const packageUsingSelector = createSelector(
  (state) => state.user,
  (app) => app.packageUsing,
);

export const packageListSelector = createSelector(
    (state) => state.user,
    (app) => app.packageList,
);
