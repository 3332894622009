import React from "react";
import {Button, Popconfirm} from "antd";

import ButtonCustomize from "../../../components/button";
import {BtnTypeEnum} from "../../../components/button/const";
import {formatInputMoney} from "../../../utils/helper";
import './buyMore.scss';
import {EBuyMoreSystem} from "../../../constants/enum";

const ServiceItem = ({item, isSameUserPkg = false, isSetting = true, onDelete, onDuplicate, onUpdate, onUserReg}) => {
    return <div className="card-service">
        <div className="title-service">
            <div className={"subtitle-service"}>
                <span>{item.name}</span>
                {isSetting && item.typeSystem > EBuyMoreSystem.NORMAL && (
                    <span>Hệ thống</span>
                )}
            </div>
            {isSetting ? (
                <>
                    {item.typeSystem === EBuyMoreSystem.NORMAL && (
                        <Popconfirm
                            title="Xóa bản ghi"
                            description="Bạn có muốn xóa bản ghi này?"
                            onConfirm={onDelete(item.id)}
                            okText="Đồng ý"
                            cancelText="Hủy"
                        >
                            <Button danger>Xóa</Button>
                        </Popconfirm>
                    )}
                    <ButtonCustomize
                        title={"Nhân bản"}
                        btnType={BtnTypeEnum.OUTLINE}
                        btnSize={32}
                        onPressHandle={onDuplicate(item)}
                    />
                    <ButtonCustomize
                        title={"Thiết lập"}
                        btnType={BtnTypeEnum.OUTLINE}
                        btnSize={32}
                        onPressHandle={onUpdate(item)}
                    />
            </>) : (
                <ButtonCustomize
                    isDisabled={!isSameUserPkg}
                    title={"Đăng ký"}
                    btnType={isSameUserPkg ? BtnTypeEnum.GREEN : BtnTypeEnum.GREY}
                    btnSize={32}
                    onPressHandle={onUserReg(item)}
                />
            )}
        </div>
        <ul className={"buy-more-desc"}>
            <li>{item.description}</li>
            <li>
                <span>{formatInputMoney(item?.price || 0)} đ</span>
                <span>{item.unit}</span>
            </li>
        </ul>
    </div>
}

export default ServiceItem;