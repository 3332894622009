import { Dropdown, notification, Form, Input, message } from "antd";
import { API_BASE_URL, KEY_USER_LOGIN } from "../../../constants/apiConstants";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import {
  AlertType,
  openNotificationWithIcon,
} from "../../../utils/AlertMessage";
import Logo from "../../svg/logo";
import LogoTesop from "../../../assets/images/logo_tesop.png";
import IconDown from "../../svg/icon-down";
import "./Header.scss";
import { authInfoSelector } from "../../../redux/slice/auth/authSelector";
import { clearAuthInfo } from "../../../redux/slice/auth/authSlice";
import React, { useEffect, useState } from "react";
import { clearState } from "../../../redux/slice/admin/adminSlice";
import { clearStateUser } from "../../../redux/slice/user/userSlice";
import Modal from "../../../components/modal/modal-card";
import ButtonCustomize from "../../../components/button";
import { BtnTypeEnum } from "../../../components/button/const";
import { useUser } from "../../../pages/client/useUser";

function Header(props) {
  const dispatch = useDispatch();
  const { changePassword } = useUser();
  const userAuth = useSelector(authInfoSelector);
  const [modalForm, setModalForm] = useState({ isOpen: false });
  const [form] = Form.useForm();
  let user = localStorage.getItem(KEY_USER_LOGIN)
    ? JSON.parse(localStorage.getItem(KEY_USER_LOGIN))
    : null;

  console.log("user", user);
  const showModal = () => {
    setModalForm({ isOpen: true });
  };

  const closeModal = () => {
    setModalForm({ isOpen: false });
    form.resetFields();
  };

  const onFinish = async (values) => {
    try {
      const { oldPassword, newPassword, confirmPassword } = values;
      if (newPassword !== confirmPassword) {
        message.error("Mật khẩu mới và mật khẩu xác nhận không khớp!");
        return;
      }

      // Gọi API để thay đổi mật khẩu
      await changePassword({
        userName: user.user_name,
        oldPassword,
        newPassword,
        confirmPassword,
      });

      setModalForm({ isOpen: false });
      form.resetFields(); // Reset lại form sau khi đổi mật khẩu thành công
    } catch (error) {
      message.error("Đổi mật khẩu thất bại, vui lòng thử lại!");
    }
  };

  const handleSaveClick = () => {
    form.submit(); // Gọi hàm submit của form để xử lý việc đổi mật khẩu
  };

  const renderFooter = () => {
    return (
      <>
        <ButtonCustomize
          title={"Hủy bỏ"}
          btnType={BtnTypeEnum.OUTLINE}
          btnSize={44}
          onPressHandle={closeModal}
        />
        <ButtonCustomize
          title={"Lưu lại"}
          btnType={BtnTypeEnum.GREEN}
          btnSize={44}
          onPressHandle={handleSaveClick}
        />
      </>
    );
  };

  function handleLogout() {
    axios
      .post(API_BASE_URL + "auth/logout", {})
      .then(function (response) {
        if (response.status === 200) {
          openNotificationWithIcon(
            notification,
            AlertType.success,
            response.data?.data || ""
          );
          localStorage.clear();
          dispatch(clearAuthInfo());
          dispatch(clearState());
          dispatch(clearStateUser());
        }
      })
      .catch(function (error) {
        if (error.response?.status !== 406) {
          openNotificationWithIcon(
            notification,
            AlertType.error,
            error?.response?.data?.message || "Lỗi hệ thống"
          );
        }
      });
  }

  const items = [
    {
      key: "1",
      label: (
        <>
          <div className="intro">Thay đổi mật khẩu</div>
        </>
      ),
      onClick: () => showModal(),
    },
    {
      key: "3",
      label: (
        <>
          <div className="intro">Đăng xuất</div>
        </>
      ),
      onClick: handleLogout,
    },
  ];
  return (
    <>
      <div className={"navbar-container"}>
        <div className="navbar">
          <div className="logo">
            {/*<a*/}
            {/*  data-v-74ce4750=""*/}
            {/*  href="/"*/}
            {/*  className=""*/}
            {/*  exact=""*/}
            {/*  id="hpanel_tracking-h_header-header_logo-link-home"*/}
            {/*>*/}
            {process.env.REACT_APP_IS_SAAS_PROD === "true" ? (
              <Logo />
            ) : (
              <img
                className="logo-responsive"
                src={LogoTesop}
                alt={""}
                width={150}
              />
            )}
            {/*</a>*/}
          </div>
          <div className="header-icons">
            {userAuth ? (
              <Dropdown
                menu={{ items }}
                placement="bottomRight"
                arrow={{ pointAtCenter: true }}
              >
                <div className="items">
                  <div className={"icon-item"}>
                    <div className="back-ground"></div>
                    <span className="icon-title">
                      {userAuth?.full_name || ""}
                    </span>
                    <IconDown />
                  </div>
                </div>
              </Dropdown>
            ) : (
              <>
                <div className="wrap-btn-authen">
                  <button className="buttonHeader --shine">Đăng nhập</button>
                  <button className="buttonHeader --shine">Đăng ký</button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        showModal={modalForm.isOpen}
        onClose={closeModal}
        item={"Đổi mật khẩu"}
        footerNode={renderFooter}
      >
        <Form
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          form={form}
          className="form-change-password"
        >
          <Form.Item
            label={<div className="title-item">Mật khẩu hiện tại</div>}
            name="oldPassword"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập mật khẩu",
              },
            ]}
          >
            <Input className="card-input" placeholder="Nhập vào" />
          </Form.Item>
          <Form.Item
            label={<div className="title-item">Mật khẩu mới</div>}
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập mật khẩu mới",
              },
            ]}
          >
            <Input className="card-input" placeholder="Nhập vào" />
          </Form.Item>
          <Form.Item
            label={<div className="title-item">Nhập lại mật khẩu mới</div>}
            name="confirmPassword"
            rules={[
              { required: true, message: "Vui lòng nhập lại mật khẩu mới!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Mật khẩu xác nhận không khớp!")
                  );
                },
              }),
            ]}
          >
            <Input className="card-input" placeholder="Nhập vào" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default withRouter(Header);
