import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import {
  createBuyMoreServiceApi, deleteBuyMoreServiceApi, getAllListBuyMoreApi,
  updateBuyMoreServiceApi, getRequestBuyMoreRegisteredApi, getRequestDeleteExpiredServiceApi, uploadFileApi
} from "../../../redux/slice/admin/adminApis";
import {
  AlertType,
  openNotificationWithIcon,
} from "../../../utils/AlertMessage";
import { ERROR_API } from "../../../constants/errorMessages";

export const useBuyMore = () => {
  const dispatch = useDispatch();

  const getAllListBuyMore = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const resData = await dispatch(getAllListBuyMoreApi(payload)).unwrap();
        resolve({ isSuccess: true, data: resData });
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

    const handleCreateBuyMore = useCallback((payload) => {
        return new Promise(async (resolve) => {
          
            try {
              const onSuccess = (res) =>{
                resolve(res);
            }
              if (Array.isArray(payload.packageId)) {
                for (const packageId of payload.packageId) {
                  const newPayload = { ...payload, packageId }; // Tạo payload mới với từng packageId
                  await dispatch(createBuyMoreServiceApi({params:newPayload,onSuccess})).unwrap();
                }
              } else {
                await dispatch(createBuyMoreServiceApi({params:payload,onSuccess})).unwrap();
              }
              openNotificationWithIcon(notification, AlertType.success, "Tạo dịch vụ thành công");
            } catch (e) {
                openNotificationWithIcon(notification, AlertType.error, e?.response?.data?.message || ERROR_API);
                resolve({isSuccess: false});
            }
        });
    }, []);

    const handleUpdateBuyMore = useCallback((payload) => {
        return new Promise(async (resolve) => {
            try {
                const onSuccess = (res) =>{
                    resolve(res);
                }
                await dispatch(updateBuyMoreServiceApi({params:payload,onSuccess})).unwrap();
                openNotificationWithIcon(notification, AlertType.success, "Cập nhật dịch vụ thành công");
                resolve({isSuccess: true});
            } catch (e) {
                openNotificationWithIcon(notification, AlertType.error, e?.response?.data?.message || ERROR_API);
                resolve({isSuccess: false});
            }
        });
    }, []);

 

  const handleDeleteBuyMore = useCallback((buyMoreId) => {
    return new Promise(async (resolve) => {
      try {
        await dispatch(deleteBuyMoreServiceApi(buyMoreId)).unwrap();
        openNotificationWithIcon(
          notification,
          AlertType.success,
          "Xóa dịch vụ thành công"
        );
        resolve({ isSuccess: true });
      } catch (e) {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          e?.response?.data?.message || ERROR_API
        );
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const getRequestBuyMoreRegApi = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const resData = await dispatch(
          getRequestBuyMoreRegisteredApi(payload)
        ).unwrap();
        resolve({ isSuccess: true, data: resData });
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const getRequestDetailExpiredService = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const resData = await dispatch(
          getRequestDeleteExpiredServiceApi(payload)
        ).unwrap();
        resolve({ isSuccess: true, data: resData });
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);
  const uploadFileImg = useCallback((formData) => {
    return new Promise(async (resolve) => {
        try {
            const resData = await dispatch(uploadFileApi(formData)).unwrap();
            resolve({ isSuccess: true, data: resData});
        } catch (e) {
            resolve({ isSuccess: false });
        }
    });
}, []);
  return {
    getAllListBuyMore,
    handleCreateBuyMore,
    handleUpdateBuyMore,
    handleDeleteBuyMore,
    getRequestBuyMoreRegApi,
    uploadFileImg,
    getRequestDetailExpiredService,
  };
};
